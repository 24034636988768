import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Request } from "../datatypes";

// Define the initial state type
export interface RequestList {
  value: Request[];
}

// Initial state with type annotation
const initialState: RequestList = {
  value: [],
};

// Create the slice with typed actions
export const requestListSlice = createSlice({
  name: "requestList",
  initialState,
  reducers: {
    addRequest: (state, action: PayloadAction<Request>) => {
      state.value.push(action.payload)
    },
    set: (state, action: PayloadAction<Request[]>) => {
      state.value = action.payload
    },
  },
});

// Export actions with types
export const { addRequest, set } = requestListSlice.actions;

// Export the reducer
export default requestListSlice.reducer;
