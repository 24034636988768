import { configureStore } from '@reduxjs/toolkit'
import requestListReducer from './data/requestListSlice'
import userReducer from './data/userSlice'

export const store =  configureStore({
    reducer: {
        requestList: requestListReducer,
        user: userReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch