import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import Home from "./Pages/Home";
import Events from "./Pages/Events";
import Projects from "./Pages/About";
import Membership from "./Pages/Membership";
import JoinUs from "./Pages/JoinUs";
import Contact from "./Pages/Contact";
// import Finance from "./Finance/Finance";
import Finance from "./Finance/Finance"
import LoginPage from "./Financev2/login";
import Dashboard from "./Financev2/dashboard/dashboard";

const Redirect = () => {
  window.location.href = "https://nuseds.notion.site/";
  return null;
};

function App() {
  return (
    <MantineProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Redirect />} />
          <Route path="/home" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/about" element={<Projects />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/joinus" element={<JoinUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/purchase" element={<Finance />}>
            {/* <Route index element={<LoginPage />} />
            <Route path="dashboard" element={<Dashboard />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
