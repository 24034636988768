import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../datatypes";

// Initial state with type annotation
const initialState: User = {
  nuid: "",
  token: "",
  permissions: [],
  actions: []
};

// Create the slice with typed actions
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return action.payload; // Correct way to update the entire state
    },
  },
});

// Export actions with types
export const { setUser } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;
